import { React, Component } from "react";

const terms = () => {
  return (
    <center>
      <div className="">
        <br />
        <h1>Terms and Condtitions</h1>
        <p>
          By using the "https://thesweetpop.co.in/" website ("Service"),
          or any of our services, you are agreeing to be bound by the following
          terms and conditions ("Terms of Service"). IF YOU ARE ENTERING INTO
          THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU
          REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY, ITS
          AFFILIATES AND ALL USERS WHO ACCESS OUR SERVICES THROUGH YOUR ACCOUNT
          TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS "YOU" OR "YOUR"
          SHALL REFER TO SUCH ENTITY, ITS AFFILIATES AND USERS ASSOCIATED WITH
          IT. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH
          THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY
          NOT USE THE SERVICES. If The Sweet Pop makes material changes
          to these Terms, we will notify you by email or by posting a notice on
          our site before the changes are effective. Any new features that
          augment or enhance the current Service, including the release of new
          tools and resources, shall be subject to the Terms of Service.
          Continued use of the Service after any such changes shall constitute
          your consent to such changes. You can review the most current version
          of the Terms of Service at any time at: Terms and Conditions Emails &
          SMS Messages By signing up, you agree to receive Promotional &
          Marketing email updates about our courses. We don't send any spam
          messages. Copyright and Content Ownership All the material posted on
          the website by our team is our copyright and you would need explicit
          permission from us for reproducing, copying or using our content in
          any way. The look and feel of the Service is copyright © 2020
          The Sweet Pop All rights reserved. You may not duplicate, copy,
          or reuse any portion of the HTML/CSS, Javascript, or visual design
          elements or concepts without express written permission from us.
          Payment, Refunds and Cancellation Terms: All payments are processed
          using exteremly secure services provided by ccavenue.com We do not
          store any credit/debit card information on our server. Visit our
          Cancellation and Refund page for details about refunds.
        </p>
      </div>
    </center>
  );
};

export default terms;
