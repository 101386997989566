import { React, Component } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import Logo from "../../images/logo.png";
import LogoCelebrations from '../../images/logo_celebrations.png'

class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="/">
            <center>
              <img src={LogoCelebrations} alt="" style={{width: "100px"}}/>
            </center>
          </a>
          <button
            className="navbar-toggler abc"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars barsicon"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="thisul">
              <Link to="/">
                <li className="nav-item">Home</li>
              </Link>
              <a href="/allProducts?q=birthday-cake">
                <li className="nav-item">Birthday</li>
              </a>
              <a href="/allProducts?q=anniversary-cake">
                <li className="nav-item">ANNIVERSARY</li>
              </a>
              <a href="https://api.whatsapp.com/send/?phone=918290919225&text=Hi">
                <li className="nav-item">Contact Us</li>
              </a>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
