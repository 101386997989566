import { React, Component } from "react";

const shipping = () => {
  return (
    <center>
      <div className="">
        <br />
        <h1>Shipping And Delivery Policy</h1>
        <p>
          1. General Information All orders are subject to product availability.
          If an item is not in stock at the time you place your order, we will
          notify you and refund you the total amount of your order, using the
          original method of payment. 2.  Any shipments outside of Bhilwara are not available
          at this time. 3. Delivery Time An estimated delivery time will be
          provided to you once your order is placed. Delivery times are
          estimates and commence from the date of shipping, rather than the date
          of order. Delivery times are to be used as a guide only and are
          subject to the acceptance and approval of your order. Unless there are
          exceptional circumstances, we make every effort to fulfill your order
          within [15] business days of the date of your order. Business day mean
          Monday to Friday, except holidays. Please note we do not ship on
          [Sundays]. Date of delivery may vary due to carrier shipping
          practices, delivery location, method of delivery, and the items
          ordered. Products may also be delivered in separate shipments. 4.
          Delivery Instructions [You can provide special delivery instructions
          on the check-out page of our website.] 5. Shipping Costs Shipping
          costs are based on the weight of your order and the delivery method.
          To find out how much your order will cost, simple add the items you
          would like to purchase to your cart, and proceed to the checkout page.
          Once at the checkout screen, shipping charges will be displayed.
          Additional shipping charges may apply to remote areas or for large or
          heavy items. You will be advised of any charges on the checkout page.
          Sales tax is charged according to the province or territory to which
          the item is shipped.
        </p>
      </div>
    </center>
  );
};

export default shipping;
