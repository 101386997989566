import { React, Component } from "react";

const contactus = () => {
  return (
    <center>
      <div className=""><br />
        <h1>Contact Us</h1>
        <p>+91 8290919225</p>
        <p>
          Address - A,350, near pragya bhawan, Sanjay Colony, Bhilwara - 311001
          (Rajasthan)
        </p>
        <p>Email - thesweetpopbhl@gmail.com</p>
      </div>
    </center>
  );
};

export default contactus;
