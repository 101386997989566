import { React, Component } from "react";

const loader = (props) => {
  return (
    <center>
      <div
        class="spinner-border"
        role="status"
        style={{
          display: props.display ? "block" : "none",
        }}
      >
        <span class="sr-only">Loading...</span>
      </div>
    </center>
  );
};

export default loader;
