import { React, Component } from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";
import Loader from "../design/loading";
import Courses from "./Products";

class AllProducts extends Component {
  state = {
    viewProducts: [],
    fliterBy: "",
    isLoading: false,
  };

  componentDidMount() {
    let searchTerm = this.props.location.search.split("?q=")[1];
    if (searchTerm !== undefined) {
      if (searchTerm === "birthday-cake") {
        this.setState({ fliterBy: "Birthday Cake" });
      } else if (searchTerm === "anniversary-cake") {
        this.setState({ fliterBy: "Anniversary Cake" });
      } else if (searchTerm === "girls-cake") {
        this.setState({ fliterBy: "Girls Cake" });
      } else if (searchTerm === "boys-cake") {
        this.setState({ fliterBy: "Boys Cake" });
      } else if (searchTerm === "bento-cake") {
        this.setState({ fliterBy: "bento cakes" });
      } else if (searchTerm === "other-cakes") {
        this.setState({ fliterBy: "Other Occassions" });
      } else if (searchTerm === "hampers") {
        this.setState({ fliterBy: "hampers"})
      } else if (searchTerm === "newbornkids") {
        this.setState({ fliterBy: "newbornkids"})
      } else if (searchTerm === "engagement-cakes") {
        this.setState({ fliterBy: "engagement"})
      } else if (searchTerm === "exclusive-cupcake") {
        this.setState({ fliterBy: "Exclusive cup cakes"})
      } else if (searchTerm === "trending-cakes") {
        this.setState({ fliterBy: "trending cakes"})
      }
    }
    this.fetchProducts()
      .then((res) => {
        this.setState({ viewProducts: res.reverse() });
        this.setState({ isLoading: false });
      })
      .catch((err) => console.log(err));
  }

  fetchProducts = async () => {
    this.setState({ isLoading: true });
    const response = await fetch(
      "https://sweetpopbackend.vercel.app/api/products/all"
    );
    const body = await response.json();

    return body;
  };

  render() {
    let a = this.state.viewProducts.map((product, i) => {
      if (
        this.state.fliterBy === product.category ||
        this.state.fliterBy === ""
      ) {
        return (
          <Link to={"/product-details/" + product._id}>
            <div>
              <div className="card-own">
                <div className="card-image-container-products">
                  <img src={this.state.viewProducts[i].image} alt="" />
                </div>
                <div className="heading-container">
                  <p className="heading-product">
                    {this.state.viewProducts[i].name}
                  </p>
                </div>
                <p className="price-product">
                  &#x20B9;{this.state.viewProducts[i].discountedPrice}{" "}
                  <span>&#x20B9;{this.state.viewProducts[i].oldPrice}</span>
                </p>
                <button className="enroll">Buy Now</button>
              </div>
            </div>
          </Link>
        );
      }
    });

    return (
      <div>
        <center>
          <br />
          <h1>Buy Cakes Online</h1>
        </center>
        <center>
          <div className="filter-container">
            <div class="dropdown-own">
              <button class="dropbtn">
                Filter By Occasion <i class="fas fa-chevron-down"></i>
              </button>
              <div class="dropdown-content-own">
                <a
                  href="#"
                  onClick={() => {
                    this.setState({ fliterBy: "Birthday Cake" });
                  }}
                >
                  Birthday Cakes
                </a>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({ fliterBy: "Anniversary Cake" });
                  }}
                >
                  Anniversary Cakes
                </a>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({ fliterBy: "Boys Cake" });
                  }}
                >
                  Boys Cakes
                </a>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({ fliterBy: "Girls Cake" });
                  }}
                >
                  Girls Cakes
                </a>
                <a
                  href="#"
                  onClick={() => {
                    this.setState({ fliterBy: "" });
                  }}
                >
                  Show All
                </a>
              </div>
            </div>
          </div>
        </center>
        <Loader display={this.state.isLoading} />
        <div className="allProducts">{a}</div>
      </div>
    );
  }
}

export default AllProducts;
