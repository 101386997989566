import { React, Component } from "react";
import Navbar from "./components/design/navbar";
import HomePage from "./components/Screens/HomePage";
import ProductDetails from "./components/Screens/ProuctDetails";
import CourseImages from "./components/Screens/CourseImages";
import AllProducts from "./components/Screens/AllProducts";
import Footer from './components/design/footer';
import ContactPage from './components/design/Contactus';
import TermsPage from './components/design/terms';
import RefundsPage from './components/design/refunds';
import ShippingPage from './components/design/shipping';
import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/allProducts" exact component={AllProducts}>
          </Route>
          <Route path="/contactus" exact component={ContactPage}>
          </Route>
          <Route path="/shipping" exact component={ShippingPage}>
          </Route>
          <Route path="/terms" exact component={TermsPage}>
          </Route>
          <Route path="/refunds" exact component={RefundsPage}>
          </Route>
          <Route path='/product-details/:id' component={ProductDetails}>
          </Route>
          <Route><h1>Not Found</h1></Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
