import { React, Component } from "react";
import './footer.css';

const footer = () => {
    return(
        <div className="footer">
            <p>&copy; 2022 The Sweet Pop | Celebrations 24*7 - All Rights Reserved.</p>
            <a href="https://merchant.razorpay.com/policy/KdGNJROOAGFRfa/privacy">Privacy Policy || </a>
            <a href="/contactus">Contact Us || </a>
            <a href="/terms">Terms and Condtitions || </a>
            <a href="/refunds">Cancellations and Refunds ||</a>
            <a href="/shipping">Shipping And Delivery</a>
        </div>
    );
}

export default footer;