import { React, Component } from "react";
import Navbar from "../design/navbar";
import Courses from "./Products";
import GirlHero from "../../images/girls-hro.jpg";
import BirthdayHero from "../../images/birthday-hero.jpg";
import AnniversaryHero from "../../images/anniversary-hero.jpg";
import BoysHero from "../../images/boys-hero.jpg";
import ChocolateCake from "../../images/chocolatecake-1.jpg";
import ButterscotchCake from "../../images/butterscotch.jpg";
import StrawberryCake from "../../images/strawberry-cake.jpg";
import PineappleCake from "../../images/pineapple-cake.jpg";
import RedVelvetCake from "../../images/red-velvet.jpg";
import VannilaCake from "../../images/vannila-cake.jpg";
import WeddingCake from "../../images/wedding-cake.jpg";
import FareWellCake from "../../images/farewell-cake.jpg";
import Category from "../design/Category";
import SideBanner from "../design/SideBanner";
import BannerImage from '../../images/midnight-cake-banner1.png';
import IceCreamBanner from '../../images/ice-cream-cake-banner.jpg';
import DryCake from '../../images/dry-cake.jpg';
import CakeBanner from '../../images/cake banner.png'
import Cupcakes from '../../images/cupcake.jpg'
import CakeStand from '../../images/cake-stand.jpg'
import NewBornKids from '../../images/newbornkids.jpg';
import Engagement from '../../images/engagementcake.jpg';
import { Link } from "react-router-dom";
import "./HomePage.css";

class HomePage extends Component {
  render() {
    return (
      <div>
        <div className="carousel_outer">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active" data-interval="2000">
                <img
                  src="https://m-i1.fnp.com/assets/images/custom/mob-cakes-2020/hero-banner/cake-hero-banner-mob.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="prev"
            >
              <i class="fas fa-caret-square-left"></i>

              <span class="sr-only">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="next"
            >
              <i class="fas fa-caret-square-right"></i>

              <span class="sr-only">Next</span>
            </button>
          </div>
        </div>
        <br />

        <center>
          <h1>Cakes By Occasion</h1>
        </center>
        <section class="category-hero-section">
          <Link to="/allProducts?q=other-cakes">
            <div className="category-item">
              <div className="circle">
                <img src={DryCake} alt="" />
              </div>
              <h6 className="category-item-heading">Desserts</h6>
              <p className="category-item-subtitle">& Combos</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=birthday-cake">
            <div className="category-item">
              <div className="circle">
                <img src={BirthdayHero} alt="" />
              </div>
              <h6 className="category-item-heading">Birthday</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=anniversary-cake">
            <div className="category-item">
              <div className="circle">
                <img src={AnniversaryHero} alt="" />
              </div>
              <h6 className="category-item-heading">Anniversary</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=boys-cake">
            <div className="category-item">
              <div className="circle">
                <img src={BoysHero} alt="" />
              </div>
              <h6 className="category-item-heading">Boys</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=girls-cake">
            <div className="category-item">
              <div className="circle">
                <img src={GirlHero} alt="" />
              </div>
              <h6 className="category-item-heading">Girls</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=bento-cake">
            <div className="category-item">
              <div className="circle">
                <img src={IceCreamBanner} alt="" />
              </div>
              <h6 className="category-item-heading">Bento & Mini</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=exclusive-cupcake">
            <div className="category-item">
              <div className="circle">
                <img src={Cupcakes} alt="" />
              </div>
              <h6 className="category-item-heading">Exclusive</h6>
              <p className="category-item-subtitle">Cup Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=hampers">
            <div className="category-item">
              <div className="circle">
                <img src={CakeStand} alt="" />
              </div>
              <h6 className="category-item-heading">Cake Stand</h6>
              <p className="category-item-subtitle">& Hampers</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=newbornkids">
            <div className="category-item">
              <div className="circle">
                <img src={NewBornKids} alt="" />
              </div>
              <h6 className="category-item-heading">Kids</h6>
              <p className="category-item-subtitle">Cake</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=engagement-cakes">
            <div className="category-item">
              <div className="circle">
                <img src={Engagement} alt="" />
              </div>
              <h6 className="category-item-heading">Engagement</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
          <Link to="/allProducts?q=trending-cakes">
            <div className="category-item">
              <div className="circle">
                <img src="https://pic.warmoven.in/catalog/product/cache/e4dbbe88580d6bb18ca174b8700f08f3/image/17008cc6/half-round-pinata-cake-dark-chocolate.jpg" alt="" />
              </div>
              <h6 className="category-item-heading">Trending</h6>
              <p className="category-item-subtitle">Cakes</p>
              <button className="btn-shopnow">Shop Now</button>
            </div>
          </Link>
        </section>

        <section>
          <div className="bakingWorkshop">
            <h1>
              Latest Additions{" "}
              <span
                className="view-all-button"
                style={{
                  color: "blue",
                  textDecoration: "italic",
                }}
              >
                <Link to="/allProducts">{"View All"}</Link>
              </span>
            </h1>
            <div className="products-carousel">
              <Courses maxLength="4" filter="all" />
            </div>
          </div>
        </section>

        <br />

        <section>
          <div className="bakingWorkshop">
            <h1>
              Birthday Cakes{" "}
              <span
                className="view-all-button"
                style={{
                  color: "blue",
                  textDecoration: "italic",
                }}
              >
                <Link to="allProducts">{"View All"}</Link>
              </span>
            </h1>
            <Courses maxLength="3" filter="Birthday Cake" />
          </div>
        </section>

        {/*
          1) Sizes
          2) Flavour
          3) Date And time
          4) Pickup And Delivery
          5) Name(Optional)
          6) Other Instructions(Optional)
          7) Contact Details
          8) Payment
        */}
      </div>
    );
  }
}

export default HomePage;
