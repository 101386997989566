import { React, Component } from "react";
import Navbar from "../design/navbar";
import "./HomePage.css";
import { Link } from "react-router-dom";
import Loader from "../design/loading";
const axios = require("axios");

class Courses extends Component {
  state = {
    viewProducts: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchProducts()
      .then((res) => {
        this.setState({ isLoading: false });
        this.setState({ viewProducts: res.reverse() });
      })
      .catch((err) => console.log(err));
  }

  fetchProducts = async () => {
    this.setState({ isLoading: true });
    const response = await fetch(
      "https://sweetpopbackend.vercel.app/api/products/all"
    );
    const body = await response.json();

    return body;
  };

  updateState = (doc) => {
    this.setState({ viewProducts: doc });
  };

  render() {
    let countI = 0;
    let a = this.state.viewProducts.map((product, i) => {
      if (countI <= 3 || this.props.maxLength === "infinite") {
        if (
          this.props.filter === product.category ||
          this.props.filter === "all"
        ) {
          countI = countI + 1;
          return (
            <Link to={"/product-details/" + product._id}>
              <div>
                <div className="course">
                  <div className="card-image-container">
                    <img src={this.state.viewProducts[i].image} alt="" />
                  </div>
                  <p className="heading">{this.state.viewProducts[i].name}</p>
                  <p className="price">
                    &#x20B9;{this.state.viewProducts[i].discountedPrice}{" "}
                    <span>&#x20B9;{this.state.viewProducts[i].oldPrice}</span>
                  </p>
                  <button className="enroll">Buy Now</button>
                </div>
              </div>
            </Link>
          );
        }
      }
    });

    return (
      <div>
        <Loader display={this.state.isLoading} />
        <div className="courses-carousel">{a}</div>
      </div>
    );
  }
}

export default Courses;
