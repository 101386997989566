import { React, Component } from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";
import CreditCard from "../../images/credit-card.png";
import moneyIcon from "../../images/money.png";
import TimePicker from "react-bootstrap-time-picker";
import Loader from "../design/loading";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const axios = require("axios");

class ProductDetails extends Component {
  state = {
    viewProducts: [],
    isLoading: false,
    startTime: "00:00",
    startDate: "2022-12-16",
    productPrice: "499",
    deliveryApplied: false,
    paymentButtonText: "",
    paymentButtonDispay: false,
    timePickerValue: "",
    coupenDisplay: false,
    discountPrice: "0",
    isCoupenLoading: false,
    productDetails: [
      {
        name: "hello",
        variants: {
          sizes: [{ size: "1/2k" }, { size: "1/2k" }],
          flavours: ["Pineapple", "Chocolate"],
        },
      },
    ],
  };

  componentDidMount() {
    const d = new Date();
    let hour = d.getHours();
    let newHour;
    if (hour >= 19) {
      newHour = `23:00`;
    } else {
      newHour = `${hour + 5}:00`;
    }
    this.setState({ startTime: newHour });
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    if (month <= 9) {
      month = "0" + month.toString();
    }
    let day = d.getDate();
    if (day <= 9) {
      day = "0" + day.toString();
    }
    let presentDate = `${year}-${month}-${day}`;
    this.setState({ startDate: presentDate });
    this.fetchProducts().then((doc) => {
      this.setState({ productDetails: doc });
      this.setState({ productPrice: doc[0].discountedPrice });
    });
  }

  fetchProducts = async () => {
    this.setState({ isLoading: true });
    let id = this.props.match.params.id;
    const response = await fetch(
      "https://sweetpopbackend.vercel.app/api/products/all"
    );
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    let finalData = [];
    for (let index = 0; index < body.length; index++) {
      if (body[index]._id === id) {
        finalData = [body[index]];
      }
    }
    this.setState({ isLoading: false });
    return finalData;
  };

  selectTime = (event) => {
    let date = event.target.value;
    let newDate = date.split("-");
    console.log(newDate);
    const d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    if (month <= 9) {
      month = "0" + month.toString();
    }
    let day = d.getDate();
    if (day <= 9) {
      day = "0" + day.toString();
    }
    let hour = d.getHours();
    if (newDate[2] !== day.toString()) {
      this.setState({ startTime: "10:00" });
    } else {
      let newHour;
      if(hour <= 18) {
        newHour = `${hour + 6}:00`;
      } else {
        alert("No Time slots available for this date");
        document.getElementById("delivery-date").value = "";
      }
      this.setState({ startTime: newHour });
    }
  };

  changePrice = (event, deliveryApplied) => {
    let value = event.target.value;
    let newValue = value.split(",");
    this.setState({ productPrice: newValue[1] });
  };

  changePriceDelivery = (event) => {
    if (event.target.value === "Delivery") {
      this.setState({ deliveryApplied: true });
    } else {
      this.setState({ deliveryApplied: false });
    }
  };

  submit = (e) => {
    e.preventDefault();
    let whatsapp = document.getElementById("whatsapp").value;
    //open Modal
    if (whatsapp.length === 10) {
      document.getElementById("open-modal-button").click();
    } else{
      alert("Enter Correct Phone Number");
    }
  };

  addOrder = () => {
    if (this.state.paymentButtonText === "Proceed To Pay") {
      this.PayNow();
    } else {
      this.addOrderToDb();
    }
  };

  addOrderToDb = () => {
    this.setState({ isLoading: true });
    let size = document.getElementById("size").selectedOptions[0].value;
    let flavour = document.getElementById("flavour").selectedOptions[0].value;
    let deliveryDate = document
      .getElementById("delivery-date")
      .value.toString();
    let deliveryTime = this.state.timePickerValue / 3600;
    if (deliveryTime > 12) {
      deliveryTime = (deliveryTime - 12).toString() + ":00pm";
    } else {
      deliveryTime = deliveryTime.toString() + ":00am";
    }
    let nameOnCake = document.getElementById("name-on-cake").value;
    let customerName = document.getElementById("customer-name").value;
    let whatsapp = document.getElementById("whatsapp").value;
    let address = document.getElementById("address").value;
    let deliveryApplies = this.state.deliveryApplied;
    let productName = this.state.productDetails[0].name;
    let productId = this.state.productDetails[0]._id;
    let productImage = this.state.productDetails[0].image;
    let productPrice = this.calculateFinalPrice();
    let occasion = document.getElementById("occasion-select").value;
    let comments = document.getElementById("comments").value;
    let paymentType;
    if (this.state.paymentButtonText === "Confirm Order") {
      paymentType = "COD";
    } else {
      paymentType = "Prepaid";
    }

    if (whatsapp.length === 10) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "bRiuAsxA90",
      };

      axios
        .post(
          "https://sweetpopbackend.vercel.app/api/order/add",
          {
            customerName: customerName,
            customerAddress: address,
            customerWhatsapp: whatsapp,
            selectedProductName: productName,
            selectedProductSize: size,
            selectedProductFlavour: flavour,
            selectedProductId: productId,
            selectedProductPrice: productPrice,
            selectedProductImage: productImage,
            deliveryTime: deliveryTime,
            deliveryDate: deliveryDate,
            paymentMethod: paymentType,
            isDelivery: deliveryApplies,
            nameOnCake: nameOnCake,
            occasion: occasion
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            alert(
              "Your Order is placed Successfully! Thanks for shopping with us"
            );
            this.setState({ isLoading: false });
            document.getElementById("close-modal").click();
          } else {
            return;
          }
        })
        .catch((error) => {
          alert("error occured: " + error.message);
        });
    } else {
      document.getElementById("close-modal").click();
      alert("Please add correct Phone Number");
      this.setState({ isLoading: false });
    }
  };

  PayNow = () => {
    this.setState({ isLoading: true });
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
    script.onload = this.displayPopup;
  };

  displayPopup = () => {
    let customerName = document.getElementById("customer-name").value;
    let whatsapp = document.getElementById("whatsapp").value;
    let price = this.calculateFinalPrice();
    this.setState({ isLoading: false });
    const options = {
      key: "rzp_live_yZfZ0QaXegtRFR",
      amount: price * 100,
      currency: "INR",
      name: "The Sweet Pop",
      description: "Payment for order",
      handler: this.addOrderToDb,
      prefill: {
        name: customerName,
        contact: whatsapp,
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  calculateFinalPrice = () => {
    return (
      parseInt(this.state.productPrice) +
      parseInt(this.state.deliveryApplied ? 40 : 0) -
      parseInt(this.state.discountPrice)
    );
  };

  coupenCode = async () => {
    this.setState({isCoupenLoading: true});
    const headers = {
      "Content-Type": "application/json",
      Authorization: "bRiuAsxA90",
    };

    let coupen = document.getElementById("coupen").value;

    axios
      .post(
        "https://sweetpopbackend.vercel.app/api/coupen/find",
        {
          coupenCode: coupen,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Coupen code Applied Successfully");
          let discount =
            (parseInt(response.data.discountAmount) / 100) *
            parseInt(this.state.productPrice);
          this.setState({ discountPrice: discount });
          this.setState({isCoupenLoading: false});
        } else {
          alert("Invalid Coupen Code");
          this.setState({isCoupenLoading: false});
        }
      })
      .catch( (error) => {
        this.setState({isCoupenLoading: false});
        alert("Invalid Coupen Code");
      });
  };

  render() {
    let flavours;
    if (this.state.productDetails.length !== 0) {
      if (
        this.state.productDetails[0].flavour !== "Red Velvet" &&
        this.state.productDetails[0].flavour !== "Rasmalai" &&
        this.state.productDetails[0].flavour !== "Lotus Biscoff" &&
        this.state.productDetails[0].flavour !== "Ferraro Rocher" &&
        this.state.productDetails[0].flavour !== "DBC" &&
        this.state.productDetails[0].flavour !== "Cookie Cream" &&
        this.state.productDetails[0].flavour !== "Kesar Badam"

      ) {
        flavours = (
          <select name="" id="flavour" class="form-input">
            <option value="" disabled selected>
              Select Flavour
            </option>
            <option value="Vannila">Vannila</option>
            <option value="Pineapple">Pineapple</option>
            <option value="Strawberry">Strawberry</option>
            <option value="Butterscotch">Butterscotch</option>
            <option value="Blueberry">Blueberry</option>
            <option value="Coffee">Coffee</option>
          </select>
        );
      } else {
        flavours = (
          <select name="" id="flavour" class="form-input">
            <option value="" disabled selected>
              Select Flavour
            </option>
            <option value={this.state.productDetails[0].flavour}>
              {this.state.productDetails[0].flavour}
            </option>
          </select>
        );
      }
    }

    return (
      <div>
        <div
          className="main-content"
          style={{
            display: this.state.isLoading ? "none" : "block",
          }}
        >
          <div className="course-details-image">
            <center>
              <img src={this.state.productDetails[0].image} alt="" />
            </center>
          </div>
          <div className="course-details-text">
            <div className="heading">
              <h1>{this.state.productDetails[0].name}</h1>
            </div>
            <ul>
              <li>✅Eggless</li>
              <li>✅{this.state.productDetails[0].flavour} Flavour</li>
              <li>✅100% fresh and hygenic</li>
            </ul>
            <p>Price - &#8377;{this.state.productPrice}</p>
          </div>
          <div className="course-details-menu">
            <div className="heading">
              <h2>Customization Details -</h2>
            </div>
            <form onSubmit={this.submit}>
              <select
                name=""
                id="size"
                required
                className="form-input"
                onChange={this.changePrice}
              >
                <option value="" selected disabled>
                  Select Size
                </option>
                <option
                  value={
                    this.state.productDetails[0].variants.sizes[0].size +
                    "," +
                    this.state.productDetails[0].variants.sizes[0].price
                  }
                >
                  {this.state.productDetails[0].variants.sizes[0].size} (Rs.{" "}
                  {this.state.productDetails[0].variants.sizes[0].price})
                </option>
                <option
                  value={
                    this.state.productDetails[0].variants.sizes[1].size +
                    "," +
                    this.state.productDetails[0].variants.sizes[1].price
                  }
                >
                  {this.state.productDetails[0].variants.sizes[1].size} (Rs.{" "}
                  {this.state.productDetails[0].variants.sizes[1].price}){" "}
                </option>
              </select>
              <br />

              {flavours}
              <br />

              <label for="date-input">Select Delivery Date</label>
              <br />
              <input
                type="date"
                id="delivery-date"
                name="date-input"
                min={this.state.startDate}
                className="form-input"
                required
                placeholder="Select Date"
                onChange={this.selectTime}
              />
              <br />

              <label for="time-input">Select Delivery Time</label>
              <br />

              <TimePicker
                start={this.state.startTime}
                name="time-input"
                end="24:00"
                step={60}
                onChange={(e) => {
                  this.setState({ timePickerValue: e });
                }}
                value={this.state.timePickerValue}
                className="form-input"
                required
                id="time-picker"
              />

              <select name="" id="occasion-select" className="form-input" required>
                <option value="" disabled selected>Select Occasion</option>
                <option value="Birthday">Birthday</option>
                <option value="Anniversary">Anniversary</option>
                <option value="Engagement">Engagement</option>
                <option value="Wedding">Wedding</option>
                <option value="Baby shower">Baby shower</option>
                <option value="Baby shower">1st birthday</option>
              </select><br />

              <input
                type="text"
                className="form-input"
                id="name-on-cake"
                placeholder="Name To be written on cake (Optional)"
              />
              <br />

              <input
                type="text"
                className="form-input"
                required
                id="customer-name"
                placeholder="Enter Your name"
              />
              <br />

              <input
                type="number"
                className="form-input"
                required
                id="whatsapp"
                placeholder="Enter Your Whatsapp Number"
              />
              <br />

              <textarea
                type="text"
                rows="4"
                className="form-input textarea"
                required
                id="address"
                placeholder="Enter Your Complete Address"
              />
              <br />

              <div className="picktype">
                <input
                  type="radio"
                  id="delivery"
                  name="deliveryoptions"
                  value="Delivery"
                  required
                  onChange={this.changePriceDelivery}
                />
                <label className="labelfordeliveryoptions" for="delivery">
                  Delivery
                </label>
                <span className="leftfees">&#8377;40 Extra</span>
              </div>
              <div className="picktype">
                <input
                  type="radio"
                  id="pickup"
                  name="deliveryoptions"
                  value="Pickup"
                  onChange={this.changePriceDelivery}
                />
                <label className="labelfordeliveryoptions" for="pickup">
                  Pickup
                </label>
                <br />
                <p>Celebrations 24*7, near pragya bhawan, Sanjay Colony, Bhilwara</p>
              </div>

              <input
                type="text"
                className="form-input"
                id="comments"
                placeholder="Any Other Comments? (Optional)"
              />
              <br />

              <input
                type="submit"
                id="submit-button"
                className="form-input"
                value="Order Now"
              />
              <br />
            </form>

            <button
              type="button"
              class="btn btn-primary"
              id="open-modal-button"
              data-toggle="modal"
              data-target="#exampleModal"
              style={{ display: "none" }}
            ></button>

            <center>
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="3"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Select Payment Method
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-modal"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div className="picktype">
                        <input
                          type="radio"
                          id="rzp"
                          name="paymentmethod"
                          value="rzp"
                          required
                          onChange={() => {
                            this.setState({
                              paymentButtonText: "Proceed To Pay",
                            });
                            this.setState({ paymentButtonDispay: true });
                          }}
                        />
                        <label className="labelfordeliveryoptions" for="rzp">
                          <img src={CreditCard} width="25" alt="" />{" "}
                          UPI/NetBanking/Cards
                        </label>
                      </div>
                      <div className="picktype">
                        <input
                          type="radio"
                          id="cod"
                          name="paymentmethod"
                          value="cod"
                          onChange={() => {
                            this.setState({
                              paymentButtonText: "Confirm Order",
                            });
                            this.setState({ paymentButtonDispay: true });
                          }}
                        />
                        <label className="labelfordeliveryoptions" for="cod">
                          <img src={moneyIcon} width="25" alt="" /> COD(Cash On
                          Delivery)
                        </label>
                        <br />
                      </div>

                      <div>
                        <p
                          style={{ color: "blue" }}
                          onClick={() => {
                            this.setState({ coupenDisplay: true });
                          }}
                        >
                          Have a coupen code? Click
                        </p>
                      </div>

                      <div
                        style={{
                          display: this.state.coupenDisplay ? "block" : "none",
                        }}
                      >
                        <input
                          className="form-control"
                          type="text"
                          id="coupen"
                          placeholder="Enter Coupen Code"
                        />
                        <br />
                        <button
                          className="btn btn-success"
                          onClick={this.coupenCode}
                        >
                          Apply
                        </button>
                      </div>
                      <br />

                      <div
                        style={{
                          display: this.state.isCoupenLoading
                            ? "none"
                            : "block",
                        }}
                      >
                        <h5>Subtotal: &#8377;{this.state.productPrice}</h5>
                        <p>
                          Delivery Charge:{" "}
                          {this.state.deliveryApplied ? "Rs.40" : "Pickup"}
                        </p>
                        <p
                          style={{
                            display: this.state.coupenDisplay
                              ? "block"
                              : "none",
                          }}
                        >
                          Discount: Rs.{this.state.discountPrice}
                        </p>
                        <h4>
                          Total: &#8377;
                          {parseInt(this.state.productPrice) +
                            parseInt(this.state.deliveryApplied ? 40 : 0) -
                            parseInt(this.state.discountPrice)}
                        </h4>
                      </div>

                      <div
                        class="spinner-border"
                        role="status"
                        style={{
                          display: this.state.isCoupenLoading
                            ? "block"
                            : "none",
                        }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>

                      <button
                        className="btn btn-success"
                        onClick={this.addOrder}
                        style={{
                          display: this.state.paymentButtonDispay
                            ? "block"
                            : "none",
                          backgroundColor: "#7fb62f",
                        }}
                      >
                        {this.state.paymentButtonText}
                      </button>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                        style={{ display: "none" }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          </div>
        </div>
        <br />
        <div className="loading">
          <Loader display={this.state.isLoading} />
        </div>
      </div>
    );
  }
}

export default ProductDetails;
