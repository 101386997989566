import { React, Component } from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";

class CourseImages extends Component {
  state = {
    viewProducts: [1, 2, 3, 4, 5, 6],
  };
  render() {
    return (
      <div className="course-images">
        <center>
          <h1>3 Types of Designer Cakes & Pistachio Cakesicles Workshop</h1>
          <p>Recipe Images</p>
          <div className="course-images-grid">
            <div className="imagebox">
              <img
                src="https://pr.tmcdn.in/uploads/academies/a01d2d533d75dd628bf951800cefcae9778f41a2/shared_images/img_cqQUXu8cHyHJxaKr/1607674484/small/IMG_9329.png?response-content-disposition=inline&Expires=1640502634&Signature=a0FOGmjvhHFgg18rFD6rmY~WFxhD~J-KKahsTELWXWA1DWIr9~Fx-ydWNhsGDp0AY0xAcJ2Z-tAkUHc7Q~~2f0YnETsKO2Uhx~6xJU-Vq5KpwY75uqUvhC2wFxg-k07OdfeISuoKXdJz4upy5VGdnWuqrdeGTcQHgFZHoW1IPgkndPpnSJdUOKYt7837Anna91p7kUIHI4nkWjybDm68~f1FgJsjZwf5h9QojItI1nciRuC~lik8GFOcTKP5~tmtGY-XjHSg4FrNSQrwu9z9h5L4CdHEQNyBTHf~TDtYZOuu05MvyLPpylM4nP7kY-1X3MNGOuXAijapPlBRLgE8OQ__&Key-Pair-Id=APKAIDNNTK2RAM3ROIFQ"
                alt=""
              />
            </div>
            <div className="imagebox">
              <img
                src="https://pr.tmcdn.in/uploads/academies/a01d2d533d75dd628bf951800cefcae9778f41a2/shared_images/img_WSMddDo73J6G1TmP/1607674484/small/IMG_9325.png?response-content-disposition=inline&Expires=1640502634&Signature=rz43K5WE5vOYt1VioDsqI4rTgwfP8RX-NBJT9MX3Vo4vTUxULY8m5YLrjI1c~BIpLt1r3jMvZw6tA9-aru4-DyKKzkCuGGiFUnPJwJoZ9mknASQO~O74Mi2NW1oDLX-lTsxQZZeUw~~3hSHBYTm96oAkk~kIjjMOZA5pFZ9Mm4qD3id9tKaIr6L2XB7R4LptQR1CFjtVTQreUWBLhjUlMtf2OBps0zjNWAQKfsYfcIFqFVjQ1llO18V4IR3Pue8sl7~zP-7xyRCpvbcVha~J7RSGCB8gvpURr95PG8JwOGUrpn3JiXn6zL6uK83s031l4PjiiCobOWUYPgvw~7ozcw__&Key-Pair-Id=APKAIDNNTK2RAM3ROIFQ"
                alt=""
              />
            </div>{" "}
            <div className="imagebox">
              <img
                src="https://pr.tmcdn.in/uploads/academies/a01d2d533d75dd628bf951800cefcae9778f41a2/shared_images/img_5WRT2BsrVXGGR2F3/1607674484/small/IMG_9353.png?response-content-disposition=inline&Expires=1640502634&Signature=Hay1vR7iUG71Hsqhzfo0fWTye4os6oQc5KO672LbhYX2w6pVURsGIxUE6LbWSe-AKqNULO4-5dWooNm06H~hSIM7htqD~IWxxVxTaZ10hOJUKirLX8vd43NpY90K3YmJ~qNr7ofv9ESXMeyepJ1cji-SQdqh2QJsQaeGLlj7SnvVJmSHABHToZmCirpYdu-qoNSlTNcjN0Hxw-Cgp08q8xv9A0--mr8ir-a4ZmELONygAx8TJWMAbminBy1XjZvwmtoPAlZn9UkXkkmbhcv-lo1XcEyJlXW0ga~0Wn8UeguObdDiJr70lQmrteutwG5ksqtxxDPIJZkiHQ5~L8TqvQ__&Key-Pair-Id=APKAIDNNTK2RAM3ROIFQ"
                alt=""
              />
            </div>
            <div className="imagebox">
              <img
                src="https://pr.tmcdn.in/uploads/academies/a01d2d533d75dd628bf951800cefcae9778f41a2/shared_images/img_X5X4lXrunGVWrdFC/1607674484/small/IMG_9355.png?response-content-disposition=inline&Expires=1640502634&Signature=sfuyuloVxagPZE4owU2CBtBlHcw0XEn1jOh-33DmPsR-7RklRGhNTj-TNn03PMDu5lSGKTnYrkELrzb~FuMvdzhqb96GlH9ARL9gg-PMc5JXwoevZ7xu9G17nVUlHoPvQaR7TWE1mQpYdT6-NLCwKvqZaCn6jb4Pysc3x5NWib1ojOleTi2haSXkua4T58din8XSydxfRAYJ2FNFGb9HG55SBbQaN3t5XirQg5qJj-0LElJegtz6YyljdwjIbYGzNLF36r-Xz1HfeOdkpQd~z2wWUsAPGObxARy-6A-ElWaqNMa0cELH45WYX~BDujggwQYnnFoA88-QcyFu0FjlTg__&Key-Pair-Id=APKAIDNNTK2RAM3ROIFQ"
                alt=""
              />
            </div>
          </div>
        </center>
      </div>
    );
  }
}

export default CourseImages;
