import { React, Component } from "react";

const refunds = () => {
  return (
    <center>
      <div className="">
        <br />
        <h1>Cancellations and refunds</h1>
        <p>
          Our primary goal is to have products that would make your learning
          enjoyable. If by any chance you are not satisfied with our services
          after buying the course please email us at
          thesweetpopbhl@gmail.com. If you are not satisfied with the
          purchase, please email us within 14 days and we will get back to you
          at the earliest. On further verification from our end we will initiate
          a refund. How do I request for a refund? Kindly reach out to us at
          thesweetpopbhl@gmail.com. Please mention your registered email,
          phone number and reason of cancellation in the email. We will get in
          touch with you to initiate the cancellation and refund process. When
          do I receive my refund? If you are eligible to get a refund, we will
          refund your amount within 7 working days. If you need more information
          about the policy kindly contact us.
        </p>
      </div>
    </center>
  );
};

export default refunds;
