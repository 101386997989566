import { React, Component } from "react";
import "./Category.css";
import { Link } from "react-router-dom";

class Category extends Component {
  render() {
      return(
        <div>
            <div className="category">
                <div className="image-container">
                    <img src={this.props.image} alt="" />
                    <h6>{this.props.title}</h6>
                </div>
            </div>
        </div>
      );
  }
}

export default Category;
